import { useState, useEffect, useRef } from 'react';
import customHeaders, { getSpace } from '../../common/headers';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import { useChartData } from '../../common/hooks';
import xlc_icon from './img/xlc.svg';
import pdf_icon from './img/pdf.svg';
import {
  RangeBoxDoublePeriod,
  DropDownSelector,
  Tabs
} from '@lk-gtcom/ecomlab-components';
import { NotActive } from '../../components/NotActive/NotActive';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { Page404 } from '../Page404/Page404';
import './CrossAnalytics.scss';
import {
  fetchApiFilter,
  fetchBrandFilter,
  fetchCategoryFilter,
} from '../../common/filtersFunc';
import { useDataTable } from '../../common/hooks';
import { OlegHttps, OlegHttp } from '../../fetchUrls';
import { CrossAnalyticscGeneralIndicators } from '../CrossAnalyticscGeneralIndicators/CrossAnalyticscGeneralIndicators';
import { SideTabs } from '../../components/SideTabs/SideTabs';
import useGeneralStore from '../../store/general';
import { CrossAnalyticsCharts } from './CrossAnalyticsCharts/CrossAnalyticsCharts';
import { values } from 'lodash';

const btn_tab = [
  {
    label: 'Графики',
    value: 'charts',
  },
  {
    label: 'Cравнение периодов',
    value: 'compare_period',
  },
  {
    label: 'Cравнение периодов по товарам',
    value: 'compare_period_by_product',
  },
  {
    label: 'Потери от отсутсвия остатков',
    value: 'absence_of_residues',
  },
  {
    label: 'Потери от падения конверсии',
    value: 'conversion_drop',
  },
  {
    label: 'Потери от падения просмотров',
    value: 'views_drop',
  },
  {
    label: 'Потери от подорожания товаров',
    value: 'price_increases',
  },
  {
    label: '20 лучших товаров по доле в выручке за прошлый период и их доля в текущем периоде',
    value: 'top_20',
  },
  {
    label: 'Товары с показами без продаж в сравнении с прошлым периодом',
    value: 'without_sales',
  },
  {
    label: 'Товары с рекламой без продаж',
    value: 'without_sales_with_adv',
  },
  {
    label: 'Сравнение периодов по категориям',
    value: 'compare_period_by_category',
  },
  {
    label: 'Сравнение периодов по брендам',
    value: 'compare_period_by_brand',
  },
  {
    label: 'Причины потерь продаж',
    value: 'reason_of_lost_profit_pivot',
  },
];

const mp_options = [
  {
    label: 'Ozon',
    value: 1,
  },
  {
    label: 'Wildberries',
    value: 3,
  },
  {
    label: 'Yandex',
    value: 2,
  },
];



const CrossAnalytics = () => {
  const apiIdRef = useRef();
  const activeTab = useParams()['*']

  // const [sidebarItems, setSidebarItems] = useState(sidebarItemArr);
  const [pageValue, setPageValue] = useState(btn_tab[0].value)
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedMp, setSelectedMp] = useState([{ label: 'Ozon', value: 1 }]);
  const [selectedApi, setSelectedApi] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const [dateCurrent, setDateCurrent] = useState([
    new Date().setHours(0, 0, 0, 10),
    new Date().setHours(23, 59, 59, 999),
  ]);
  const [datePrevious, setDatePrevious] = useState([
    new Date().setHours(0, 0, 0, 10) - 604800000 * 7 - 604800000 * 7,
    new Date().setHours(23, 59, 59, 999),
  ]);

  const headers = { ...customHeaders, ...getSpace(selectedSpace) }

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;
  const [currentTab, setcurrentTab] = useState(null)

  const [chartLoading, setChartLoading] = useState(false);
  const [setChartData, chartData, chartActions] = useChartData();

  const [showDropBox, setShowDropBox] = useState(false)

  const fetchTableData = (params, abortController = new AbortController()) => {
    if (!selectedApi?.value) return
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/analytics/cross/table/${pageValue}${sort?.length > 0
        ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
        : ''
        }`
    } else {
      url = `${OlegHttp}/api/v1/analytics/cross/table/${pageValue}${sort?.length > 0
        ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
        : ''
        }`
    }

    let brand_id_list;
    let category_id_list;
    let api_id_list;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = ['all'];
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = ['all'];
    }

    if (selectedApi?.value) {
      api_id_list = [selectedApi?.value];
    }

    const [date_from1, date_to1] = dateCurrent;
    const [date_from2, date_to2] = datePrevious;

    const body = JSON.stringify({
      category_id_list,
      api_id_list,
      brand_id_list,
      limit: paginatorRef?.current?.limit,
      page: paginatorRef?.current?.page,
      date_from1: new Date(date_from1 + 86401000)?.toISOString()?.split('T')?.[0],
      date_to1: new Date(date_to1)?.toISOString()?.split('T')?.[0],
      date_from2: new Date(date_from2 + 86401000)?.toISOString()?.split('T')?.[0],
      date_to2: new Date(date_to2)?.toISOString()?.split('T')?.[0],
      filters: filtersParam,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  // const fetchChart = (abortController = new AbortController()) => {
  //   setChartLoading(true);

  //   let url;
  //   if (!window.location.hostname.match('localhost')) {
  //     url = `${OlegHttps}/api/v1/analytics/cross/graph/orders`;
  //   } else {
  //     url = `${OlegHttp}/api/v1/analytics/cross/graph/orders`;
  //   }

  //   let brand_id_list;
  //   let category_id_list;
  //   let api_id_list;

  //   if (selectedBrand?.length > 0) {
  //     brand_id_list = selectedBrand.map((el) => el.value);
  //   } else {
  //     brand_id_list = ['all'];
  //   }

  //   if (selectedCategory?.length > 0) {
  //     category_id_list = selectedCategory.map((el) => el.value);
  //   } else {
  //     category_id_list = ['all'];
  //   }

  //   if (selectedApi?.value) {
  //     api_id_list = [selectedApi?.value];
  //   }

  //   const [date_from1, date_to1] = dateCurrent;
  //   const [date_from2, date_to2] = datePrevious;

  //   const body = JSON.stringify({
  //     category_id_list,
  //     api_id_list,
  //     brand_id_list,
  //     date_from1: new Date(date_from1 + 86401000)?.toISOString()?.split('T')?.[0],
  //     date_to1: new Date(date_to1)?.toISOString()?.split('T')?.[0],
  //     date_from2: new Date(date_from2 + 86401000)?.toISOString()?.split('T')?.[0],
  //     date_to2: new Date(date_to2)?.toISOString()?.split('T')?.[0]
  //   });

  //   fetch(url, {
  //     body,
  //     method: 'POST',
  //     headers,
  //     signal: abortController.signal,
  //   })
  //     .then(async (res) => {
  //       if (res.ok) {
  //         return res.json();
  //       } else {
  //         const err = await res.json();
  //         throw new Error(JSON.stringify(err));
  //       }
  //     })
  //     .then((json) => {
  //       setChartData(json);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     })
  //     .finally(() => {
  //       if (abortController.signal.aborted) return;
  //       setChartLoading(false);
  //     });

  // };

  const body_obj = () => {

    let brand_id_list;
    let category_id_list;
    let api_id_list;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = ['all'];
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = ['all'];
    }

    if (selectedApi?.value) {
      api_id_list = [selectedApi?.value];
    }

    const [date_from1, date_to1] = dateCurrent;
    const [date_from2, date_to2] = datePrevious;

    const body = JSON.stringify({
      category_id_list,
      api_id_list,
      brand_id_list,
      date_from1: new Date(date_from1 + 86401000)?.toISOString()?.split('T')?.[0],
      date_to1: new Date(date_to1)?.toISOString()?.split('T')?.[0],
      date_from2: new Date(date_from2 + 86401000)?.toISOString()?.split('T')?.[0],
      date_to2: new Date(date_to2)?.toISOString()?.split('T')?.[0]
    });

    if (selectedApi?.value) {
      return body
    } else {
      return null
    }
  }

  useEffect(() => {
    fetchTableData()
    if (selectedApi?.value) {
      body_obj()
    }
  }, [pageValue,
    selectedApi,
    selectedBrand,
    selectedCategory,
    dateCurrent,
    datePrevious
  ]);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: setApiData,
      searchParam: apiQuery,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedShop: selectedMp,
    });
  }, [apiPage, apiQuery, selectedMp, selectedSpace]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedShop: selectedMp,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    categoryPage,
    categoryQuery,
    selectedSpace,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedShop: selectedMp,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
  }, [
    categoryData,
    selectedApi,
    selectedCategory,
    brandPage,
    brandQuery,
    selectedSpace,
  ]);

  const resetTable = () => {
    paginatorRef?.current?.reset();
    paginatorRef?.current?.setTotal(0);
    setSort([]);
    setHeaders([]);
    setTable({});
  };

  useEffect(() => {
    setPageValue(activeTab);
    resetTable();
  }, [activeTab]);


  return (
    <div className="cross-analytics">
      <div className="cross-analytics__header">
        <h1 class="title_main">Кросс аналитика</h1>

        <div className="cross-analytics__range">
          <RangeBoxDoublePeriod
            dateCurrent={dateCurrent}
            datePrevious={datePrevious}
            setDateCurrent={setDateCurrent}
            setDatePrevious={setDatePrevious}
          />
        </div>
      </div>

      <div className="toolbar-top">
        <FilterContainer
          onReset={(e) => {
            setSelectedApi([]);
            setSelectedCategory([]);
            setSelectedBrand([]);
          }}
        >
          <DropDownSelector
            options_prop={mp_options}
            state={selectedMp}
            setState={(e) => setSelectedMp(e)}
            className="connections_page_selector"
            placeholder="Площадка"
            defaultValue={[{ label: 'Ozon', value: 1 }]}
            multi
          />

          <DropDownSelector
            options_prop={apiData}
            state={selectedApi}
            setState={(e) => setSelectedApi(e)}
            className="connections_page_selector"
            placeholder="Магазин"
            fetchCallback={(e) => setApiPage(e)}
            setInputChange={(e) => setApiQuery(e)}
            inputRef={true}
            ref={apiIdRef}
          //multi
          />

          <DropDownSelector
            options_prop={categoryData}
            state={selectedCategory}
            setState={(e) => setSelectedCategory(e)}
            className="connections_page_selector"
            placeholder="Категория"
            fetchCallback={(e) => setCategoryPage(e)}
            setInputChange={(e) => setCategoryQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
          />

          <DropDownSelector
            options_prop={brandData}
            state={selectedBrand}
            setState={(e) => setSelectedBrand(e)}
            className="connections_page_selector"
            placeholder="Бренд"
            fetchCallback={(e) => setBrandPage(e)}
            setInputChange={(e) => setBrandQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
          />
        </FilterContainer>
      </div>

      <div className="flex">
        <div className="cross-analytics__left-content">
          <SideTabs
            title='Содержание отчета'
            //  mp={'general-indicators'}
            pageValue={pageValue}
            setPageValue={(e) => setPageValue(e)}
            tabs={btn_tab}
          />

          <div className="cross-analytics__btn-group">
            <button className="cross-analytics__btn-download">
              <img src={xlc_icon} />
              Скачать в Excel
            </button>
            <button className="cross-analytics__btn-download">
              <img src={pdf_icon} />
              Скачать в PDF
            </button>
          </div>
        </div>

        <div className="cross-analytics__content">

          <Routes>
            <Route path="*" element={<Page404 />} />

            <Route path="charts" element={<CrossAnalyticsCharts
              body_obj={body_obj}
            />} />

            <Route path="compare_period" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="compare_period_by_product" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="absence_of_residues" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="conversion_drop" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="views_drop" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="price_increases" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="top_20" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="without_sales" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="without_sales_with_adv" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="compare_period_by_category" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="compare_period_by_brand" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />

            <Route path="reason_of_lost_profit_pivot" element={<CrossAnalyticscGeneralIndicators
              tableData={{ ...tableProps, tableUrl, tableBody }}
              paginatorRef={paginatorRef}
              tableCallback={fetchTableData}
              pageValue={pageValue}
            />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export { CrossAnalytics };
