import { useState, useEffect } from "react"
import dots_horizontal_rounded from './img/bx-dots-horizontal-rounded.svg'
import "./ButtonActionDrop.scss"

const ButtonActionDrop = ({
    btns = [],
    label = '',
    size,
    color,
    positionDrop,
    id = 1 }) => {

    const [isDrop, setIsDrop] = useState(false)

    // Обработчик на клик вне элемента
    useEffect(() => {
        const isDrop = (e) => {
            let el = e.target
            if (!el.closest(`#button-action-drop-${label + id}`)) {
                setIsDrop(false)
            }
        }
        document.addEventListener('click', isDrop)

        return () => {
            document.addEventListener('click', isDrop)
        }
    }, [])


    return (
        <div className='button-action-drop' id={`button-action-drop-${label + id}`}
            onClick={(e) => {
                e.stopPropagation()
                setIsDrop(!isDrop)

                let el = e.target;
                if (el.classList.contains(`#button-action-drop-${label + id}`)) {
                    setIsDrop(false)
                }
            }}
        >
            <button
                style={{
                    background: color ? color : ''
                }}
                className={`button-action-drop__btn ${size === 'm' ? 'button-action-drop__btn-medium' : ''} ${size === 's' ? 'button-action-drop__btn-small' : ''}`}>
                <img src={dots_horizontal_rounded} />
            </button>

            {isDrop &&
                <div className={'button-action-drop__drop-menu_active'}
                // style={{
                //     bottom: positionDrop == 'top' ? '0px' : ''
                // }}
                >
                    <div className='button-action-drop__drop-menu-inside'>
                        {btns.map(({ btn, func }) =>
                            <button className='button-action-drop__drop-menu__btn'
                                onClick={(e) => {
                                    func(id)
                                    setIsDrop(false)
                                }}
                            >{btn}</button>
                        )}
                    </div>
                </div>}
        </div>
    )
}

export { ButtonActionDrop }